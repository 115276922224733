import { FC } from 'react';
import styles from './style.module.scss';
import { ButtonLink } from '@polydice/ui/dist/Button';
import DeviseHeader from './DeviseHeader';
import SelectedAuthors from '../SelectedAuthors/Devise';
import { RecommendUser } from '../SelectedAuthors/types';
import cx from 'classnames';

interface DeviseSignUpSuccessProps {
  redirectUrl: string;
  recommendUsers: RecommendUser[];
}

const Success: FC<DeviseSignUpSuccessProps> = ({
  recommendUsers,
  redirectUrl
}) => {
  return (
    <>
      <DeviseHeader
        entry="devise_signup_success"
        subtitle="追蹤推薦的作者，接收最新食譜通知"
      />
      <div className={cx(styles.deviseMain, styles.deviseMainSignUpSuccess)}>
        <SelectedAuthors users={recommendUsers} />
        <ButtonLink
          className={styles.deviseMainButton}
          href={redirectUrl}
          styleType="contained"
          size="md"
          color="primary"
        >
          完成
        </ButtonLink>
      </div>
    </>
  );
};

const DeviseSignUpSuccess: FC<DeviseSignUpSuccessProps> = ({
  redirectUrl,
  recommendUsers
}) => {
  return (
    <main className={styles.devise}>
      <div className={styles.deviseContainer}>
        <Success redirectUrl={redirectUrl} recommendUsers={recommendUsers} />
      </div>
    </main>
  );
};

export default DeviseSignUpSuccess;
