import { useState, useEffect, FC } from 'react';
import AppStoreImg from 'images/getapp/app-store-2.svg';
import GooglePlayImg from 'images/getapp/google-play-2.svg';
import DesktopLeftImg from 'images/login-desktop.png';
import styles from './style.module.scss';
import Snackbar from '@polydice/ui/dist/Snackbar';
import DeviseHeader from './DeviseHeader';
import DeviseOther from './DeviseOther';
import EmailSignIn from './Email/EmailSignIn';
import PhoneSignIn from './Phone/PhoneSignIn';
import { getUrlParameter } from '@polydice/icook-devise-unify';
import { SignInState, SnackbarType } from './constants';
import { logger } from '../../../components/logger';

const ONELINK_DOWNLOAD = 'https://icook.onelink.me/Dl1H/oavlrnoi';

interface DeviseSignInProps {
  passwordEndpoint: string;
  magicLinkSignUpEndpoint: string;
  signUpEndpoint: string;
  signUpSuccessPath: string;
  smsAuthsEndpoint: string;
  googleAuthorizePath: string;
  facebookAuthorizePath: string;
  firebaseAuthEndpoint: string;
  forgetPasswordPath: string;
  message: string;
  RECAPTCHA_SITE_KEY: string;
}

const DeviseSignIn: FC<DeviseSignInProps> = ({
  passwordEndpoint,
  magicLinkSignUpEndpoint,
  signUpEndpoint,
  signUpSuccessPath,
  smsAuthsEndpoint,
  googleAuthorizePath,
  facebookAuthorizePath,
  firebaseAuthEndpoint,
  forgetPasswordPath,
  message,
  RECAPTCHA_SITE_KEY
}) => {
  const [entry, setEntry] = useState('');
  const [signInState, setSignInState] = useState<SignInState>(null);
  const [messageTimer, setMessageTimer] = useState<number>(0);
  const [messageType, setMessageType] = useState<SnackbarType>(null);
  const [messageText, setMessageText] = useState<string>('');

  useEffect(() => {
    const entry = getUrlParameter('entry');
    setEntry(entry);
  }, []);

  useEffect(() => {
    if (messageTimer > 0) {
      const timer = setTimeout(() => {
        setMessageTimer(messageTimer - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [messageTimer]);

  useEffect(() => {
    const redirect = getUrlParameter('redirect');
    const REDEEM_URL_REGEX = /^https:\/\/icook.(tw|dev|test)\/redeem/;
    if (REDEEM_URL_REGEX.test(redirect)) {
      const LoginFromRedeemKey = 'LoginFromRedeem';
      sessionStorage.setItem(LoginFromRedeemKey, 'true');
      window.dataLayer.push({
        event: 'VIP Tracking - Redeem Login Required'
      });
    }
  }, []);

  useEffect(() => {
    const FACEBOOK_SDK_SESSION = 'fbssls_273393289342171';
    sessionStorage.removeItem(FACEBOOK_SDK_SESSION);
  }, []);

  const gotoEmailSignIn = () => {
    logger.logEvent('SignInUp_select', {
      signinup_selection: 'email'
    });
    setSignInState('EmailSignInMagicLink');
  };

  const gotoPhoneSignIn = () => {
    logger.logEvent('SignInUp_select', {
      signinup_selection: 'phone'
    });
    setSignInState('PhoneSignIn');
  };

  const showSnackBar = (type: SnackbarType, text: string, timer?: number) => {
    setMessageType(type);
    setMessageText(text);
    setMessageTimer(type && text ? timer || 5 : 0);
  };

  return (
    <main className={styles.devise}>
      {signInState?.startsWith('EmailSignIn') && (
        <div className={styles.deviseContainer}>
          <EmailSignIn
            {...{
              passwordEndpoint,
              forgetPasswordPath,
              signUpEndpoint,
              signUpSuccessPath,
              magicLinkSignUpEndpoint,
              signInState,
              setSignInState,
              showSnackBar,
              RECAPTCHA_SITE_KEY
            }}
          />
        </div>
      )}
      {signInState?.startsWith('Phone') && (
        <div className={styles.deviseContainer}>
          <PhoneSignIn
            {...{
              smsAuthsEndpoint,
              firebaseAuthEndpoint,
              signUpEndpoint,
              signInState,
              setSignInState,
              showSnackBar
            }}
          />
        </div>
      )}
      {signInState === null && (
        <div className={styles.deviseWelcome}>
          <div className={styles.deviseWelcomeContainer}>
            <div className={styles.deviseWelcomeDesktopLeftContainer}>
              <img
                src={DesktopLeftImg}
                alt="DesktopLeftImg"
                className={styles.deviseWelcomeDesktopLeftContainerImg}
              />
              <h2 className={styles.deviseWelcomeDesktopLeftContainerTitle}>
                百萬網友都在使用的愛料理 APP!
              </h2>
              <p
                className={
                  styles.deviseWelcomeDesktopLeftContainerDescriptionTop
                }
              >
                開啟手機掃描 QR Code，
              </p>
              <p
                className={
                  styles.deviseWelcomeDesktopLeftContainerDescriptionBottom
                }
              >
                探索 290,000+ 道食譜，收藏喜愛料理更方便！
              </p>
            </div>
            <div className={styles.deviseWelcomeContent}>
              <DeviseHeader entry={entry} />
              <div id="error-container" />
              {message && <Snackbar type="error">{message}</Snackbar>}
              <DeviseOther
                googleAuthorizePath={googleAuthorizePath}
                facebookAuthorizePath={facebookAuthorizePath}
                gotoEmailSignIn={gotoEmailSignIn}
                gotoPhoneSignIn={gotoPhoneSignIn}
              />
              <hr className={styles.deviseSeparator} />
              <div className={styles.deviseDownload}>
                <h3 className={styles.deviseDownloadTitle}>立即下載 APP</h3>
                <div className={styles.deviseDownloadButtons}>
                  <a
                    href={ONELINK_DOWNLOAD}
                    className={styles.deviseDownloadIosButton}
                    onClick={() => {
                      logger.logEvent('login_bottom_btn', {
                        platform: 'ios'
                      });
                    }}
                  >
                    <img
                      src={AppStoreImg}
                      alt="App Store"
                      className={styles.deviseDownloadIosButtonImg}
                    />
                  </a>
                  <a
                    href={ONELINK_DOWNLOAD}
                    className={styles.deviseDownloadAndroidButton}
                    onClick={() => {
                      logger.logEvent('login_bottom_btn', {
                        platform: 'android'
                      });
                    }}
                  >
                    <img
                      src={GooglePlayImg}
                      alt="Google Play"
                      className={styles.deviseDownloadAndroidButtonImg}
                    />
                  </a>
                </div>
              </div>
              <div className={styles.deviseFooter}>
                繼續進行代表你同意
                <a href="https://newsroom.icook.tw/terms">服務條款</a> 與
                <a href="/terms/privacy">隱私權條款</a>
              </div>
            </div>
          </div>
        </div>
      )}
      {messageTimer > 0 && messageType && messageText && (
        <div className={styles.messageContainer}>
          <Snackbar type={messageType}>{messageText}</Snackbar>
        </div>
      )}
    </main>
  );
};

export default DeviseSignIn;
