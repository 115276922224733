import { useState, ChangeEvent, FormEvent, FC } from 'react';
import TextField from '@polydice/ui/dist/TextField';
import HelperText from '@polydice/ui/dist/HelperText';
import Button from '@polydice/ui/dist/Button';
import { getAuthToken } from 'reactjs/utils';
import { validator, showError } from '../DeviseHelper';
import { captureException } from '@sentry/browser';
import { NICKNAME_REGEX, SignInState, SnackbarType } from '../constants';
import leftArrowIcon from 'images/icons/fa-angle-left.svg';
import cx from 'classnames';
import styles from '../style.module.scss';

interface MagicLinkSignUpProps {
  signUpEndpoint: string;
  email: string;
  magicToken: string;
  setSignInState: (state: SignInState) => void;
  showSnackBar: (type: SnackbarType, text: string, timer?: number) => void;
}

const MagicLinkSignUp: FC<MagicLinkSignUpProps> = ({
  signUpEndpoint,
  email,
  magicToken,
  setSignInState,
  showSnackBar
}) => {
  const [nickname, setNickname] = useState('');
  const [nicknameHelperText, setNicknameHelperText] = useState('');
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);

  const disableButton = nickname.length === 0 || isWaitingResponse;

  const onNicknameChange = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setNickname(value);
  };
  const onNicknameBlur = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setNicknameHelperText(validator('nickname', value));
  };

  const returnToMagicLinkVerify = () => {
    setSignInState('EmailSignInMagicLinkVerify');
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsWaitingResponse(true);

    const formData = new FormData();
    formData.append('nickname', nickname.trim());
    formData.append('authenticity_token', getAuthToken());
    formData.append('email_magic_token', magicToken);

    try {
      const response = await fetch(signUpEndpoint, {
        method: 'POST',
        headers: {
          'X-XSRF-Token': getAuthToken()
        },
        credentials: 'same-origin',
        body: formData
      });

      showSnackBar('success', '等待註冊');
      const result = await response.json();
      if (response.ok) {
        showSnackBar('success', '註冊成功，請等待跳轉');
        window.location.replace(result.redirect_url);
      }
      setIsWaitingResponse(false);
    } catch (error) {
      showSnackBar('error', '註冊失敗，請稍後再試。');
      showError(true);
      captureException(error);
    }
  };

  return (
    <div>
      <div className={styles.signInHeader}>
        <button
          className={styles.signInHeaderReturnBtn}
          onClick={() => returnToMagicLinkVerify()}
        >
          <img
            src={leftArrowIcon}
            className={styles.signInHeaderReturnBtnIcon}
            alt="leftArrow"
            width={22}
            height={22}
          />
          <span>返回</span>
        </button>
        <h1>新會員註冊</h1>
        <div className={styles.signInHeaderEmpty}></div>
      </div>
      <form className={styles.deviseMain} method="POST" onSubmit={onSubmit}>
        <p>請填寫以下資料，以完成註冊。</p>
        <TextField
          className={`${styles.deviseMainTextField}`}
          label="電子信箱"
          type="email"
          inputMode="email"
          name="current-email"
          required
          autoComplete="current-email"
          value={email}
          disabled
        />
        <TextField
          className={styles.deviseMainTextField}
          label="暱稱"
          name="new-nickname"
          pattern={NICKNAME_REGEX.source}
          placeholder="最多 16 個字"
          required
          value={nickname}
          onChange={onNicknameChange}
          onBlur={onNicknameBlur}
        />
        <HelperText
          className={styles.deviseMainHelperText}
          text={nicknameHelperText}
        />
        <Button
          className={cx(styles.deviseMainButton, {
            [styles.deviseMainButtonDisabled]: disableButton
          })}
          type="submit"
          styleType="contained"
          size="lg"
          color="primary"
          unfulfilled={isWaitingResponse}
          fullWidth
          disabled={disableButton}
        >
          註冊
        </Button>
      </form>
    </div>
  );
};

export default MagicLinkSignUp;
