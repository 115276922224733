import { useState, ChangeEvent, FormEvent, FC } from 'react';
import { getAuthToken } from 'reactjs/utils';
import { captureException } from '@sentry/browser';
import { validator, showError } from '../DeviseHelper';
import TextField from '@polydice/ui/dist/TextField';
import HelperText from '@polydice/ui/dist/HelperText';
import Button from '@polydice/ui/dist/Button';
import leftArrowIcon from 'images/icons/fa-angle-left.svg';
import {
  EMAIL_REGEX,
  EMAIL_ERROR_MISMATCH,
  PASSWORD_ERROR_MISMATCH,
  SignInState
} from '../constants';
import cx from 'classnames';
import styles from '../style.module.scss';

interface PasswordSignInProps {
  passwordEndpoint: string;
  forgetPasswordPath: string;
  email: string;
  onEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onEmailBlur: (event: ChangeEvent<HTMLInputElement>) => void;
  emailHelperText: string;
  setEmailHelperText: (text: string) => void;
  setSignInState: (state: SignInState) => void;
}

const PasswordSignIn: FC<PasswordSignInProps> = ({
  passwordEndpoint,
  forgetPasswordPath,
  email,
  onEmailChange,
  onEmailBlur,
  emailHelperText,
  setEmailHelperText,
  setSignInState
}) => {
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordHelperText, setPasswordHelperText] = useState('');

  const disableButton =
    email.length === 0 || password.length === 0 || isWaitingResponse;

  const onPasswordChange = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setPassword(value);
  };
  const onPasswordBlur = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setPasswordHelperText(validator('password', value));
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    setEmailHelperText('');
    setPasswordHelperText('');
    showError(false);
    setIsWaitingResponse(true);

    const formData = new FormData();
    formData.append('user[email]', email.trim());
    formData.append('user[password]', password.trim());
    formData.append('authenticity_token', getAuthToken());

    try {
      const response = await fetch(passwordEndpoint, {
        method: 'POST',
        headers: {
          'X-XSRF-Token': getAuthToken()
        },
        credentials: 'same-origin',
        body: formData
      });

      const result = await response.json();
      if (response.ok) {
        window.location.replace(result.redirect_url);
      } else {
        // eslint-disable-next-line default-case
        switch (result.error) {
          case EMAIL_ERROR_MISMATCH:
            setEmailHelperText(EMAIL_ERROR_MISMATCH);
            break;
          case PASSWORD_ERROR_MISMATCH:
            setPasswordHelperText(PASSWORD_ERROR_MISMATCH);
            break;
        }
      }
    } catch (error) {
      showError(true);
      captureException(error);
    }

    setIsWaitingResponse(false);
  };

  const returnToMagicLinkPage = () => {
    setSignInState('EmailSignInMagicLink');
    showError(false);
    setEmailHelperText('');
    setPasswordHelperText('');
  };

  return (
    <div>
      <div className={styles.signInHeader}>
        <button
          className={styles.signInHeaderReturnBtn}
          onClick={() => returnToMagicLinkPage()}
        >
          <img
            src={leftArrowIcon}
            className={styles.signInHeaderReturnBtnIcon}
            alt="leftArrow"
            width={22}
            height={22}
          />
          <span>返回</span>
        </button>
        <h1>使用密碼登入</h1>
        <div className={styles.signInHeaderEmpty}></div>
      </div>
      <form className={styles.deviseMain} method="POST" onSubmit={onSubmit}>
        <TextField
          className={`${styles.deviseMainTextField} ${
            emailHelperText ? 'mdc-text-field--invalid' : ''
          }`}
          label="電子信箱"
          type="email"
          inputMode="email"
          name="current-email"
          pattern={EMAIL_REGEX.source}
          required
          autoComplete="current-email"
          value={email}
          onChange={onEmailChange}
          onBlur={onEmailBlur}
        />
        <HelperText
          className={styles.deviseMainHelperText}
          text={emailHelperText}
        />
        <TextField
          className={`${styles.deviseMainTextField} ${
            passwordHelperText ? 'mdc-text-field--invalid' : ''
          }`}
          label="密碼"
          type={showPassword ? 'text' : 'password'}
          name="current-password"
          minLength={6}
          required
          autoComplete="current-password"
          tail={{ type: 'text', value: showPassword ? '隱藏' : '顯示' }}
          onTailClick={() => setShowPassword((value) => !value)}
          value={password}
          onChange={onPasswordChange}
          onBlur={onPasswordBlur}
          aria-describedby="password-constraints"
        />
        <HelperText
          id="password-constraints"
          className={styles.deviseMainHelperText}
          text={passwordHelperText}
        />
        <Button
          className={cx(styles.deviseMainButton, {
            [styles.deviseMainButtonDisabled]: disableButton
          })}
          type="submit"
          styleType="contained"
          size="lg"
          color="primary"
          unfulfilled={isWaitingResponse}
          fullWidth
          disabled={disableButton}
        >
          繼續
        </Button>
        <span className={styles.passwordSignInForgotPassword}>
          <a href={forgetPasswordPath}>忘記密碼</a>或
        </span>
        <button
          className={styles.passwordSignInToEmailPage}
          onClick={() => returnToMagicLinkPage()}
        >
          驗證信箱登入
        </button>
      </form>
    </div>
  );
};

export default PasswordSignIn;
