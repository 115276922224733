import '../../assets/javascripts/components/sentry';
import '../../assets/javascripts/components/firebase';
import 'stylesheets/devise.scss';

import ReactOnRails from 'react-on-rails';
import DeviseSignIn from '../../assets/javascripts/reactjs/components/Devise/DeviseSignIn';
import DeviseSignUpSuccess from '../../assets/javascripts/reactjs/components/Devise/DeviseSignUpSuccess';
import DeviseEmail from '../../assets/javascripts/reactjs/components/Devise/DeviseEmail';
import DevisePassword from '../../assets/javascripts/reactjs/components/Devise/DevisePassword';
import { noHydrate } from '../../assets/javascripts/reactjs/utils/hydrate';

ReactOnRails.register({
  BrowserDetector: noHydrate,
  DeviseSignIn,
  DeviseSignUpSuccess,
  DeviseEmail,
  DevisePassword
});
