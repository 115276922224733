export const EMAIL_REGEX = /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}$/;

export const EMAIL_ERROR_REQUIRED = '請輸入 Email';

export const EMAIL_ERROR_INVALID = '無效的 Email';

export const EMAIL_ERROR_MISMATCH = '找不到此 Email 的註冊紀錄';

export const EMAIL_ERROR_EXIST = '此 Email 已註冊過';

export const NICKNAME_REGEX = /^.{1,16}$/;

export const NICKNAME_ERROR_REQUIRED = '請輸入暱稱';

export const NICKNAME_ERROR_INVALID = '最多 16 個字';

export const PASSWORD_REGEX = /^.{6,}$/;

export const PASSWORD_ERROR_REQUIRED = '請輸入密碼';

export const PASSWORD_ERROR_INVALID = '至少 6 個字元以上';

export const PASSWORD_ERROR_MISMATCH = '密碼錯誤';

export const CONFIRM_PASSWORD_ERROR_REQUIRED = '請確認密碼';

export const CONFIRM_PASSWORD_ERROR_MISMATCH = '密碼不一致';

export const UNEXPECTED_ERROR = '網路似乎不穩定，請稍後嘗試';

export type SignInState =
  | 'EmailSignInMagicLink'
  | 'EmailSignInPassword'
  | 'EmailSignInPasswordSignUp'
  | 'EmailSignInMagicLinkVerify'
  | 'EmailSignInMagicLinkSignUp'
  | 'PhoneSignIn'
  | 'PhoneSignUp'
  | null;

export type SnackbarType = 'error' | 'info' | 'success' | null;
