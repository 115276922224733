import {
  EMAIL_REGEX,
  EMAIL_ERROR_REQUIRED,
  EMAIL_ERROR_INVALID,
  PASSWORD_REGEX,
  PASSWORD_ERROR_REQUIRED,
  PASSWORD_ERROR_INVALID,
  NICKNAME_REGEX,
  NICKNAME_ERROR_REQUIRED,
  NICKNAME_ERROR_INVALID,
  UNEXPECTED_ERROR
} from './constants';

export const validator = (field: string, value: string) => {
  switch (field) {
    case 'email':
      if (!value) {
        return EMAIL_ERROR_REQUIRED;
      } else if (value && !EMAIL_REGEX.test(value)) {
        return EMAIL_ERROR_INVALID;
      }
      return '';

    case 'password':
      if (!value) {
        return PASSWORD_ERROR_REQUIRED;
      } else if (value && !PASSWORD_REGEX.test(value)) {
        return PASSWORD_ERROR_INVALID;
      }
      return '';

    case 'nickname':
      if (!value) {
        return NICKNAME_ERROR_REQUIRED;
      } else if (value && !NICKNAME_REGEX.test(value)) {
        return NICKNAME_ERROR_INVALID;
      }
      return '';

    default:
      return '';
  }
};

export const getTitle = (entry: string) => {
  switch (entry) {
    case 'icook_header':
    case 'icook_proudct_header':
    case 'tv_header':
      return '盡情分享與收藏你喜愛的食譜';

    case 'market_header':
      return '精選生活中的美好事物分享給你';

    case 'blog_header':
      return '解答你從飲食到生活的各種問題';

    case 'icook_footer_user':
      return '登入會員查看個人頁面';

    case 'icook_footer_setting':
      return '登入會員查看帳號設定';

    case 'icook_searchbar_write':
      return '登入會員寫食譜';

    case 'icook_footer_save':
    case 'icook_searchbar_save':
      return '登入會員查看已收藏的食譜';

    case 'vip_entry':
      return '登入會員使用 VIP 獨享服務';

    case 'icook_recipe_save':
    case 'blog_recipesave':
      return '登入會員收藏食譜';

    case 'icook_recipe_comment':
      return '登入會員後留言';

    case 'icook_recipe_like':
      return '登入會員幫食譜說讚';

    case 'icook_recipe_dish':
      return '登入會員上傳照片';

    case 'icook_recipe_follow':
      return '登入會員追蹤作者';

    case 'icook_vip_plancta':
      return '登入會員訂閱 VIP';

    case 'icook_campaign':
      return '登入會員參加活動';

    case 'icook_user_follow':
      return '登入會員追蹤其他會員';

    case 'icook_latest_to_following':
      return '查看已追蹤作者的最新食譜';

    case 'market_nav_save':
      return '登入會員查看已收藏的商品';

    case 'icook_footer_order':
    case 'market_order':
      return '登入會員查看我的訂單';

    case 'market_order_list':
      return '登入會員查看商品訂單';

    case 'market_product_pay':
      return '登入會員購買商品';

    case 'market_product_save':
      return '登入會員收藏商品';

    case 'devise_signup':
      return '加入愛料理會員';

    case 'devise_signup_success':
      return '註冊成功';

    case 'devise_phone':
      return '輸入暱稱完成註冊';

    case 'devise_email':
      return '寄發密碼重設驗證信';

    case 'devise_password':
      return '設定新密碼';

    default:
      return '盡情收藏與分享你喜愛的食譜';
  }
};

import { createElement } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import Snackbar from '@polydice/ui/dist/Snackbar';

export const showError = (show: boolean) => {
  const container = document.getElementById('error-container');
  if (container) {
    if (show) {
      render(
        // eslint-disable-next-line react/no-children-prop
        createElement(Snackbar, {
          type: 'error',
          children: UNEXPECTED_ERROR
        }),
        container
      );
    } else {
      unmountComponentAtNode(container);
    }
  }
};
