import { FC } from 'react';
import styles from './style.module.scss';
import iCookIcon from 'images/icook-logo-footer.svg';
import { getTitle } from './DeviseHelper';

interface DeviseHeaderProps {
  entry: string;
  subtitle?: string;
}

const DeviseHeader: FC<DeviseHeaderProps> = ({ entry, subtitle }) => {
  return (
    <section className={styles.deviseHeader}>
      <a href="/">
        <img src={iCookIcon} alt="愛料理" className={styles.deviseHeaderIcon} />
      </a>
      <h3 className={styles.deviseHeaderTitle}>{getTitle(entry)}</h3>
      {subtitle && <p>{subtitle}</p>}
    </section>
  );
};

export default DeviseHeader;
