import { FC, useState, useEffect } from 'react';
import { SignInState, SnackbarType } from '../constants';
import cx from 'classnames';
import magicLinkSentImg from 'images/magic-link-sent.svg';
import leftArrowIcon from 'images/icons/fa-angle-left.svg';
import styles from '../style.module.scss';

interface MagicLinkVerifyProps {
  email: string;
  magicToken: string;
  setMagicToken: (token: string) => void;
  setSignInState: (state: SignInState) => void;
  showSnackBar: (type: SnackbarType, text: string, timer?: number) => void;
}

interface TokenEvent extends Event {
  data?: string;
}

const MagicLinkVerify: FC<MagicLinkVerifyProps> = ({
  email,
  magicToken,
  setMagicToken,
  setSignInState,
  showSnackBar
}) => {
  const [seconds, setSeconds] = useState(0);
  const [outOfRangeSend, setOutOfRangeSend] = useState(false);
  const [isSending, setIsSending] = useState(true);
  const [SSEOpen, setSSEOpen] = useState(true);
  const [sse, setSSE] = useState<EventSource | null>(null);

  const remainProgress = (60 - seconds) / 60 / 0.01;
  const sendButtonDisabled = seconds > 0 || outOfRangeSend || isSending;

  useEffect(() => {
    if (seconds > 0) {
      const timer = setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [seconds]);

  useEffect(() => {
    if (SSEOpen) {
      setIsSending(true);
      const newSSE = new EventSource(
        `/magic_link_auths?email=${encodeURIComponent(email)}&name=web`
      );
      setSSE(newSSE);

      return () => {
        if (sse) {
          setSSEOpen(false);
          setIsSending(false);
        }
      };
    }
    return () => {};
  }, [SSEOpen]);

  useEffect(() => {
    let newToken = '';
    if (sse) {
      sse.addEventListener('token', (event: TokenEvent) => {
        const { email_magic_token } = JSON.parse(event.data || '{}');
        newToken = email_magic_token;
        setMagicToken(newToken);
        showSnackBar('info', '一個新的驗證連結已發送到您的電子郵件');
        setSeconds(60);
        if (newToken) setIsSending(false);
      });

      sse.addEventListener('newmember', () => {
        setSignInState('EmailSignInMagicLinkSignUp');
      });
      sse.addEventListener('timeout', (event: TokenEvent) => {
        const { message } = JSON.parse(event.data || '{}');
        if (message) {
          showSnackBar('error', message);
        }
        setMagicToken('');
        setSSEOpen(false);
        sse.close();
      });
      sse.addEventListener('error', (event: TokenEvent) => {
        const { message } = JSON.parse(event.data || '{}');
        if (message) {
          if (message.includes('請求登入連結次數過多')) {
            setOutOfRangeSend(true);
            setSSEOpen(false);
            sse.close();
            showSnackBar('error', '已超過每小時發送限制，請過段時間再試');
          }
        }
      });

      sse.onerror = (error: TokenEvent) => {
        if (sse.readyState === 2) return;
        if (!error?.data) {
          setIsSending(false);
          sse.close();
        }
        if (!newToken && !magicToken) {
          showSnackBar('error', 'Email 發送錯誤，請稍後再試');
          setSSEOpen(false);
        }
      };

      // detect if the connection is closed
      const interval = setInterval(() => {
        if (sse.readyState === 2 && SSEOpen && (newToken || magicToken)) {
          const newSSE = new EventSource(
            `/magic_link_auths?name=web&token=${newToken || magicToken}`
          );
          setSSE(newSSE);
        }
      }, 5000);

      return () => {
        sse.close();

        if (interval) {
          clearInterval(interval);
        }
      };
    }
    return () => {};
  }, [sse]);

  useEffect(() => {
    if (sse && magicToken) {
      sse.addEventListener('login', async () => {
        window.location.href = `/magic_link_auths/redirect?token=${magicToken}`;
      });
    }
  }, [sse, magicToken]);

  return (
    <div>
      <div className={styles.signInHeader}>
        <button
          className={styles.signInHeaderReturnBtn}
          onClick={() => setSignInState('EmailSignInMagicLink')}
        >
          <img
            src={leftArrowIcon}
            className={styles.signInHeaderReturnBtnIcon}
            alt="leftArrow"
            width={22}
            height={22}
          />
          <span>返回</span>
        </button>
        <h1>需要進行驗證</h1>
        <div className={styles.signInHeaderEmpty}></div>
      </div>
      <div className={styles.magicLinkVerifyImgContainer}>
        <img
          src={magicLinkSentImg}
          alt="magicLinkSent"
          width={240}
          height={228}
        />
      </div>
      <p className={styles.magicLinkVerifyText}>
        若要繼續，請完成此驗證步驟。我們已經把驗證連結發送到電子郵件：{email}。
      </p>
      <button
        className={cx(styles.magicLinkVerifyProgressBtn, {
          [styles.magicLinkVerifyProgressBtnDisabled]: sendButtonDisabled
        })}
        disabled={sendButtonDisabled}
        onClick={() => setSSEOpen(true)}
      >
        {isSending && (
          <div className={styles.magicLinkVerifyProgressBtnFront}>
            發送中...
          </div>
        )}
        {outOfRangeSend && (
          <div className={styles.magicLinkVerifyProgressBtnFront}>
            超過驗證次數
          </div>
        )}
        {!isSending && !outOfRangeSend && (
          <>
            <div
              className={styles.magicLinkVerifyProgressBtnFront}
              style={{
                clipPath: `inset(0 0 0 ${remainProgress}%)`
              }}
            >
              重新發送驗證信 {seconds > 0 && `${seconds}s`}
            </div>
            <div className={styles.magicLinkVerifyProgressBtnBack}>
              重新發送驗證信 {seconds > 0 && `${seconds}s`}
            </div>
          </>
        )}
      </button>
      <span className={styles.signInFooter}>
        <span style={{ display: 'block', marginRight: 8 }}>或</span>
        <button onClick={() => setSignInState(null)}>
          嘗試其他方式繼續
        </button>
      </span>
    </div>
  );
};

export default MagicLinkVerify;
