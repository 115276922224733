import { ChangeEvent, FC, FormEvent, useState } from 'react';
import styles from './style.module.scss';
import TextField from '@polydice/ui/dist/TextField';
import HelperText from '@polydice/ui/dist/HelperText';
import Button from '@polydice/ui/dist/Button';
import DeviseHeader from './DeviseHeader';
import { validator, showError } from './DeviseHelper';
import { getAuthToken } from 'reactjs/utils';
import { captureException } from '@sentry/browser';

interface DevisePasswordProps {
  endpoint: string;
  resetPasswordToken: string;
}

const DevisePassword: FC<DevisePasswordProps> = ({
  endpoint,
  resetPasswordToken
}) => {
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [password, setPassword] = useState('');
  const [passwordHelperText, setPasswordHelperText] = useState('');
  const onPasswordChange = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setPassword(value);
  };
  const onPasswordBlur = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setPasswordHelperText(validator('password', value));
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    showError(false);
    setIsWaitingResponse(true);

    const formData = new FormData();
    formData.append('user[password]', password.trim());
    formData.append('user[reset_password_token]', resetPasswordToken);
    formData.append('authenticity_token', getAuthToken());

    try {
      const response = await fetch(endpoint, {
        method: 'PUT',
        headers: {
          'X-XSRF-Token': getAuthToken()
        },
        credentials: 'same-origin',
        body: formData
      });

      const result = await response.json();
      window.location.replace(result.redirect_url);
    } catch (error) {
      showError(true);
      captureException(error);
    }

    setIsWaitingResponse(false);
  };

  return (
    <main className={styles.devise}>
      <div className={styles.deviseContainer}>
        <DeviseHeader entry="devise_password" />
        <div id="error-container" />
        <form className={styles.deviseMain} method="POST" onSubmit={onSubmit}>
          <TextField
            className={styles.deviseMainTextField}
            label="密碼"
            type={showPassword ? 'text' : 'password'}
            name="new-password"
            placeholder="至少 6 個字元以上"
            minLength={6}
            required
            tail={{ type: 'text', value: showPassword ? '隱藏' : '顯示' }}
            onTailClick={() => setShowPassword((value) => !value)}
            value={password}
            onChange={onPasswordChange}
            onBlur={onPasswordBlur}
            aria-describedby="password-constraints"
          />
          <HelperText
            id="password-constraints"
            className={styles.deviseMainHelperText}
            text={passwordHelperText}
          />
          <Button
            className={styles.deviseMainButton}
            type="submit"
            styleType="contained"
            size="lg"
            color="primary"
            unfulfilled={isWaitingResponse}
            fullWidth
          >
            確定
          </Button>
        </form>
      </div>
    </main>
  );
};

export default DevisePassword;
