import { FC } from 'react';
import styles from './style.module.scss';
import { triggerAlertDialog } from '@polydice/ui/dist/Dialog';
import { logger } from '../../../components/logger';
import IconGoogleImage from 'images/icons/icon-google-color.svg';
import IconEmailImage from 'images/icons/email-icon.svg';

interface DeviseOtherProps {
  googleAuthorizePath: string;
  facebookAuthorizePath: string;
  gotoEmailSignIn?: () => void;
  gotoPhoneSignIn?: () => void;
}

const DeviseOther: FC<DeviseOtherProps> = ({
  googleAuthorizePath,
  facebookAuthorizePath,
  gotoEmailSignIn = () => {},
  gotoPhoneSignIn = () => {}
}) => {
  const isInApp = () => {
    const useragent = navigator.userAgent;
    const rules = [
      'WebView',
      '(iPhone|iPod|iPad)(?!.*Safari/)',
      'Android.*(wv)'
    ];
    const regex = new RegExp(`(${rules.join('|')})`, 'ig');
    return Boolean(useragent.match(regex));
  };

  const redirectGoogle = () => {
    logger.logEvent('SignInUp_select', {
      signinup_selection: 'google'
    });
    window.location.replace(googleAuthorizePath);
  };

  const onGoogleClick = () => {
    const inApp = isInApp();
    if (inApp) {
      triggerAlertDialog({
        children: `在此瀏覽器使用 Google 登入可能會遇到問題。我們建議您使用其他瀏覽器開啟，或改用其他方式登入。`,
        onNegativeButton: () => redirectGoogle(),
        positiveButtonText: '確定',
        negativeButtonText: '繼續嘗試'
      });
    } else {
      redirectGoogle();
    }
  };

  const onFacebookClick = () => {
    logger.logEvent('SignInUp_select', {
      signinup_selection: 'facebook'
    });
    window.location.replace(facebookAuthorizePath);
  };

  return (
    <section className={styles.deviseOther}>
      <button className={styles.deviseOtherPhone} onClick={gotoPhoneSignIn}>
        <i className={`icon-mobile-solid ${styles.deviseOtherPhoneIcon}`} />
        使用 手機號碼 繼續
      </button>
      <button className={styles.deviseOtherEmail} onClick={gotoEmailSignIn}>
        <img
          src={IconEmailImage}
          className={styles.deviseOtherEmailIcon}
          alt="Email"
          width={20}
          height={20}
        />
        使用 電子信箱 繼續
      </button>
      <button className={styles.deviseOtherGoogle} onClick={onGoogleClick}>
        <img
          src={IconGoogleImage}
          className={styles.deviseOtherGoogleIcon}
          alt="Google"
          width={20}
          height={20}
        />
        使用 Google 繼續
      </button>
      <button className={styles.deviseOtherFacebook} onClick={onFacebookClick}>
        <i
          className={`icon-facebook-solid ${styles.deviseOtherFacebookIcon}`}
        />
        使用 Facebook 繼續
      </button>
    </section>
  );
};

export default DeviseOther;
