import { FC, useEffect, FormEvent } from 'react';
import TextField from '@polydice/ui/dist/TextField';
import HelperText from '@polydice/ui/dist/HelperText';
import { EMAIL_REGEX, SignInState } from '../constants';
import leftArrowIcon from 'images/icons/fa-angle-left.svg';
import keepLoginImage from 'images/keep-login.svg';
import cx from 'classnames';
import styles from '../style.module.scss';

interface EmailSignInProps {
  email: string;
  setEmail: (email: string) => void;
  onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEmailBlur: (event: React.ChangeEvent<HTMLInputElement>) => void;
  emailHelperText: string;
  setEmailHelperText: (text: string) => void;
  setSignInState: (state: SignInState) => void;
}

const MagicLinkSignIn: FC<EmailSignInProps> = ({
  email,
  setEmail,
  onEmailChange,
  onEmailBlur,
  emailHelperText,
  setEmailHelperText,
  setSignInState
}) => {
  useEffect(() => {
    setEmail('');
    setEmailHelperText('');
  }, [setEmail, setEmailHelperText]);

  const handleEmailInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onEmailBlur(e);
  };

  const disableButton = email.length === 0 || emailHelperText.length > 0;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!disableButton) {
      setSignInState('EmailSignInMagicLinkVerify');
    }
  };

  const returnLoginPage = () => {
    setSignInState(null);
    setEmailHelperText('');
  };

  return (
    <div>
      <div className={styles.signInHeader}>
        <button
          className={styles.signInHeaderReturnBtn}
          onClick={returnLoginPage}
          type="button"
        >
          <img
            src={leftArrowIcon}
            className={styles.signInHeaderReturnBtnIcon}
            alt="leftArrow"
            width={22}
            height={22}
          />
          <span>返回</span>
        </button>
        <h1>使用 電子信箱 繼續</h1>
        <div className={styles.signInHeaderEmpty}></div>
      </div>
      <form onSubmit={handleSubmit}>
        <TextField
          className={`${styles.deviseMainTextField}`}
          label="電子信箱"
          type="email"
          inputMode="email"
          name="current-email"
          pattern={EMAIL_REGEX.source}
          required
          autoComplete="current-email"
          value={email}
          onChange={onEmailChange}
          onBlur={(e) => handleEmailInputBlur(e)}
        />
        <HelperText
          className={styles.deviseMainHelperText}
          text={emailHelperText}
        />
        <div className={styles.signInIllustration}>
          <div className={styles.signInIllustrationLeftArea}>
            <h2 className={styles.signInIllustrationTitle}>
              <span role="img" aria-label="star">
                🌟
              </span>{' '}
              登入不再需要記得密碼
            </h2>
            <p className={styles.signInIllustrationText}>
              使用電子信箱繼續，無需再費時記憶密碼。我們會發送一封電子郵件給你，只需點擊郵件中的連結即可。
            </p>
          </div>
          <img
            src={keepLoginImage}
            alt="keepLoginImage"
            width={114}
            height={114}
          />
        </div>
        <button
          className={cx(styles.signInMainBtn, {
            [styles.signInMainBtnMock]: disableButton
          })}
          disabled={disableButton}
          type="submit"
        >
          繼續
        </button>
      </form>
      <div className={styles.signInFooter}>
        或 已經有帳號？
        <button
          onClick={() => setSignInState('EmailSignInPassword')}
          type="button"
        >
          使用密碼登入
        </button>
      </div>
      <div className={styles.signInFooter}>
        沒有帳號？
        <button
          onClick={() => setSignInState('EmailSignInPasswordSignUp')}
          type="button"
        >
          使用電子信箱註冊
        </button>
      </div>
    </div>
  );
};

export default MagicLinkSignIn;
