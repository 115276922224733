import { FC } from 'react';
import styles from './style.module.scss';
import { FollowUserButton } from 'reactjs/components/FollowUserButton';
import { RecommendUser } from './types';
import cx from 'classnames';

export const SelectedAuthors: FC<{ users: RecommendUser[] }> = ({ users }) => {
  return (
    <>
      {users.length > 0 && (
        <ul
          className={cx(
            styles.selectedAuthorsList,
            styles.selectedAuthorsListDevise
          )}
        >
          {users.map(
            ({
              username,
              nickname,
              avatar,
              link,
              description,
              followersCount,
              recipesCount,
              followed,
              followUrl,
              notificationUrl,
              enableNotification
            }) => (
              <li
                className={cx(
                  styles.selectedAuthorItem,
                  styles.selectedAuthorItemDevise
                )}
                key={username}
              >
                <div className={styles.selectedAuthorContainer}>
                  <figure className={styles.selectedAuthor}>
                    <a className={styles.selectedAuthorCover} href={link}>
                      <img
                        className={styles.selectedAuthorCoverImg}
                        src={avatar}
                        alt={nickname}
                      />
                    </a>
                    <figcaption className={styles.selectedAuthorInfo}>
                      <a className={styles.selectedAuthorInfoLink} href={link}>
                        <strong className={styles.selectedAuthorName}>
                          {nickname}
                        </strong>
                        <div className={styles.selectedAuthorMeta}>
                          {recipesCount > 0 && (
                            <div className={styles.selectedAuthorMetaRecipes}>
                              {recipesCount} 食譜
                            </div>
                          )}
                          {followersCount > 0 && (
                            <div>{followersCount} 粉絲</div>
                          )}
                        </div>
                      </a>
                    </figcaption>
                  </figure>
                  <FollowUserButton
                    username={username}
                    followUrl={followUrl}
                    notificationUrl={notificationUrl}
                    followed={followed}
                    enableNotification={enableNotification}
                    withoutConnected
                  />
                </div>
                <p className={styles.selectedAuthorDescription}>
                  {description}
                </p>
              </li>
            )
          )}
        </ul>
      )}
    </>
  );
};

export default SelectedAuthors;
