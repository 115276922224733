import { ChangeEvent, FC, FormEvent, useState } from 'react';
import styles from './style.module.scss';
import { EMAIL_REGEX, EMAIL_ERROR_MISMATCH } from './constants';
import Snackbar from '@polydice/ui/dist/Snackbar';
import TextField from '@polydice/ui/dist/TextField';
import HelperText from '@polydice/ui/dist/HelperText';
import Button from '@polydice/ui/dist/Button';
import DeviseHeader from './DeviseHeader';
import { validator, showError } from './DeviseHelper';
import { getAuthToken } from 'reactjs/utils';
import { captureException } from '@sentry/browser';

interface DeviseEmailProps {
  endpoint: string;
  signInPath: string;
}

const DeviseEmail: FC<DeviseEmailProps> = ({ endpoint, signInPath }) => {
  const [seconds, setSeconds] = useState(0);
  const [showMessage, setShowMessage] = useState(false);

  const [email, setEmail] = useState('');
  const [emailHelperText, setEmailHelperText] = useState('');
  const onEmailChange = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setEmail(value);
  };
  const onEmailBlur = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setEmailHelperText(validator('email', value));
  };

  const countdown = () => {
    let second = 30;
    setSeconds(second);
    const id = window.setInterval(() => {
      if (second > 0) {
        second = second - 1;
        setSeconds(second);
      } else {
        window.clearInterval(id);
        setShowMessage(false);
      }
    }, 1000);
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    showError(false);
    setEmailHelperText('');

    const formData = new FormData();
    formData.append('user[email]', email.trim());
    formData.append('authenticity_token', getAuthToken());

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'X-XSRF-Token': getAuthToken()
        },
        credentials: 'same-origin',
        body: formData
      });

      if (response.ok) {
        setShowMessage(true);
        countdown();
      } else {
        setEmailHelperText(EMAIL_ERROR_MISMATCH);
      }
    } catch (error) {
      showError(true);
      captureException(error);
    }
  };

  return (
    <main className={styles.devise}>
      <div className={styles.deviseContainer}>
        <DeviseHeader entry="devise_email" />
        <div id="error-container" />
        {showMessage && (
          <Snackbar title="已將驗證信寄送至你的信箱" type="success">
            已發送重設密碼驗證信，請至你的信箱點選重設密碼。
          </Snackbar>
        )}
        <form className={styles.deviseMain} method="POST" onSubmit={onSubmit}>
          <TextField
            className={`${styles.deviseMainTextField} ${
              emailHelperText ? 'mdc-text-field--invalid' : ''
            }`}
            label="Email"
            type="email"
            inputMode="email"
            name="user[email]"
            pattern={EMAIL_REGEX.source}
            required
            value={email}
            onChange={onEmailChange}
            onBlur={onEmailBlur}
          />
          <HelperText
            className={styles.deviseMainHelperText}
            text={emailHelperText}
          />
          <Button
            className={`${styles.deviseMainButton} ${styles.deviseMainButtonCountDown}`}
            type="submit"
            styleType="contained"
            size="lg"
            color="primary"
            unfulfilled={seconds !== 0}
            fullWidth
          >
            {`傳送重設連結${seconds ? `（${seconds}）` : ''}`}
          </Button>
          <a className={styles.deviseMainLink} href={signInPath}>
            返回登入
          </a>
        </form>
      </div>
    </main>
  );
};

export default DeviseEmail;
