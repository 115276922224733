import { useState, ChangeEvent, FC } from 'react';
import MagicLinkSignIn from './MagicLinkSignIn';
import MagicLinkSignUp from './MagicLinkSignUp';
import MagicLinkVerify from './MagicLinkVerify';
import PasswordSignIn from './PasswordSignIn';
import PasswordSignUp from './PasswordSignUp';
import { validator } from '../DeviseHelper';
import { SignInState, SnackbarType } from '../constants';

interface EmailSignInProps {
  passwordEndpoint: string;
  forgetPasswordPath: string;
  signUpEndpoint: string;
  signUpSuccessPath: string;
  magicLinkSignUpEndpoint: string;
  signInState: SignInState;
  setSignInState: (state: SignInState) => void;
  showSnackBar: (type: SnackbarType, text: string, timer?: number) => void;
  RECAPTCHA_SITE_KEY: string;
}

const EmailSignIn: FC<EmailSignInProps> = ({
  passwordEndpoint,
  forgetPasswordPath,
  signUpEndpoint,
  signUpSuccessPath,
  magicLinkSignUpEndpoint,
  signInState,
  setSignInState,
  showSnackBar,
  RECAPTCHA_SITE_KEY
}) => {
  const [email, setEmail] = useState('');
  const [emailHelperText, setEmailHelperText] = useState('');
  const [magicToken, setMagicToken] = useState<string>('');

  const onEmailChange = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setEmail(value);
  };

  const onEmailBlur = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setEmailHelperText(validator('email', value));
  };

  return (
    <>
      {signInState === 'EmailSignInMagicLink' && (
        <MagicLinkSignIn
          {...{
            email,
            setEmail,
            onEmailChange,
            onEmailBlur,
            emailHelperText,
            setEmailHelperText,
            setSignInState
          }}
        />
      )}
      {signInState === 'EmailSignInPassword' && (
        <PasswordSignIn
          {...{
            passwordEndpoint,
            forgetPasswordPath,
            email,
            onEmailChange,
            onEmailBlur,
            emailHelperText,
            setEmailHelperText,
            setSignInState
          }}
        />
      )}
      {signInState === 'EmailSignInMagicLinkVerify' && (
        <MagicLinkVerify
          {...{
            email,
            magicToken,
            setMagicToken,
            setSignInState,
            showSnackBar
          }}
        />
      )}
      {signInState === 'EmailSignInMagicLinkSignUp' && (
        <MagicLinkSignUp
          {...{
            signUpEndpoint: magicLinkSignUpEndpoint,
            email,
            magicToken,
            showSnackBar,
            setSignInState
          }}
        />
      )}
      {signInState === 'EmailSignInPasswordSignUp' && (
        <PasswordSignUp
          {...{
            signUpEndpoint,
            signUpSuccessPath,
            email,
            onEmailChange,
            onEmailBlur,
            emailHelperText,
            setEmailHelperText,
            setSignInState,
            RECAPTCHA_SITE_KEY
          }}
        />
      )}
    </>
  );
};

export default EmailSignIn;
