import { useState, FC, useCallback } from 'react';
import PhoneValidation from 'reactjs/components/PhoneValidation';
import PhoneSignUp from './PhoneSignUp';
import { SignInState, SnackbarType } from '../constants';
import { captureFetchException } from '../../../../components/sentry';
import { getAuthToken } from 'reactjs/utils';

interface PhoneSignInProps {
  smsAuthsEndpoint: string;
  firebaseAuthEndpoint: string;
  signUpEndpoint: string;
  signInState: SignInState;
  setSignInState: (state: SignInState) => void;
  showSnackBar: (type: SnackbarType, text: string, timer?: number) => void;
}

const PhoneSignIn: FC<PhoneSignInProps> = ({
  smsAuthsEndpoint,
  firebaseAuthEndpoint,
  signUpEndpoint,
  signInState,
  setSignInState,
  showSnackBar
}) => {
  const [phoneToken, setPhoneToken] = useState('');
  const [firebaseToken, setFirebaseToken] = useState('');

  const onClose = useCallback(() => {
    setSignInState(null);
  }, [setSignInState]);

  const onFail = useCallback(
    (error: string) => {
      showSnackBar('error', error);
    },
    [showSnackBar]
  );

  const onOtpSubmit = useCallback(
    async (_phone: string, phone_token: string, otp: string) => {
      try {
        const response = await fetch(smsAuthsEndpoint, {
          headers: {
            'X-CSRF-Token': getAuthToken(),
            'Content-Type': 'application/json'
          },
          method: 'POST',
          credentials: 'same-origin',
          body: JSON.stringify({
            phone_token,
            otp
          })
        });

        if (response.ok) {
          if (response.status === 206) {
            setPhoneToken(phone_token);
            setSignInState('PhoneSignUp');
          } else {
            const { redirect_url } = await response.json();
            window.location.replace(redirect_url);
          }
        } else {
          const { error } = await response.json();
          return error;
        }
      } catch (error) {
        captureFetchException({ endpoint: smsAuthsEndpoint });
        return '驗證請求失敗，請稍後再試。';
      }
      return null;
    },
    [setSignInState, smsAuthsEndpoint]
  );

  const onFirebaseSuccess = useCallback(
    async (token: string) => {
      try {
        const response = await fetch(firebaseAuthEndpoint, {
          headers: {
            'X-CSRF-Token': getAuthToken(),
            'Content-Type': 'application/json'
          },
          method: 'POST',
          credentials: 'same-origin',
          body: JSON.stringify({
            firebase_id_token: token
          })
        });

        if (response.ok) {
          if (response.status === 206) {
            setFirebaseToken(token);
            setSignInState('PhoneSignUp');
          } else {
            const { redirect_url } = await response.json();
            window.location.replace(redirect_url);
          }
        } else {
          const { error } = await response.json();
          onFail(error);
        }
      } catch (error) {
        captureFetchException({ endpoint: smsAuthsEndpoint });
        onFail('驗證請求失敗，請稍後再試。');
      }
    },
    [firebaseAuthEndpoint, onFail, setSignInState, smsAuthsEndpoint]
  );

  return (
    <>
      {signInState === 'PhoneSignIn' && (
        <PhoneValidation
          {...{
            popup: false,
            onClose,
            onOtpSubmit,
            onFirebaseSuccess,
            onFail
          }}
        />
      )}
      {signInState === 'PhoneSignUp' && (
        <PhoneSignUp
          {...{
            signUpEndpoint,
            phoneToken,
            firebaseToken,
            setSignInState,
            showSnackBar
          }}
        />
      )}
    </>
  );
};

export default PhoneSignIn;
